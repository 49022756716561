import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class ReportSQSService extends BaseService {
  constructor() {
    super(constants.REPORT_DOWNLOAD_SQS_SERVICE, 'ReportSQSService');
  }

  handleEvent(eventName, event) {
    return this.getClient('evergreen').post(constants.REPORT_DOWNLOAD_SQS_URL, event.payload);
  }

  handleResponse(response) {
    const { data } = response;
    if (data) {
      const notification = {
        open: true,
        type: 'success',
        message: 'Report generation initiated successfully.',
        duration: 5000
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      setTimeout(() => {
        Framework.getStoreRegistry().getStore('notificationState').set({ open: false });
      }, notification.duration);
    } else {
      const notification = {
        open: true,
        type: 'error',
        message: 'No Data Found',
        duration: 5000
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      setTimeout(() => {
        Framework.getStoreRegistry().getStore('notificationState').set({ open: false });
      }, notification.duration);
    }
  }

  handleError(error) {
    const { response } = error;
    const notification = {
      open: true,
      type: 'error',
      message: response?.data?.message || 'Error initiating report generation.',
      duration: 5000
    };
    Framework.getStoreRegistry().getStore('notificationState').set(notification);
    setTimeout(() => {
      Framework.getStoreRegistry().getStore('notificationState').set({ open: false });
    }, notification.duration);
  }
}
