/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * Berlin Services
 * @author Sarathkumar Venkatachalam
 */

import axios from 'axios';
import * as constants from '../../Common/constants';
import globalContext from '../../Context/globalContext';

function getRequestInterceptor() {
  return (request) => {
    if (globalContext.userId) {
      request.headers[constants.HEADER_USER_ID] = globalContext.userId;
    }
    if (globalContext.newThread && globalContext.threadId) {
      request.headers[constants.THREAD_ID] = globalContext.threadId;
    } else {
      globalContext.newThread = true;
      request.headers[constants.NEW_THREAD] = true;
    }
    return request;
  };
}

function getResponseInterceptor() {
  return (response) => {
    if (!globalContext.threadId && response.data && response.data.threadId) {
      globalContext.threadId = response.data.threadId;
    }
    return response;
  };
}

export default class BerlinServices {
  constructor() {
    this.cancelTokenSource = axios.CancelToken.source();
    const headers = {
      'Content-Type': 'application/json',
      'x-berlin-tenant-id': globalContext.tenantId,
      'x-berlin-tenant-key': globalContext.tenantKey
    };
    this.berlinClient = axios.create({
      baseURL: constants.BERLIN_BASE_URL[globalContext.env] || constants.BERLIN_BASE_URL.qa,
      cancelToken: this.cancelTokenSource.token,
      timeout: 180000,
      headers
    });
  }

  handleCancelRequest() {
    this.cancelTokenSource.cancel('Request cancelled');
  }

  fetchTutorData(payload, type, headerParams = {}, token, callback, enableNewValidateAnswer = false) {
    this.berlinClient.defaults.timeout = 180000;
    let endPoint = `/3.0/generate/${constants.CHAT}`;
    if (type === constants.QUIZ) {
      endPoint = `/2.0/${constants.QUIZ}/fetch`;
    } else if (type === constants.SUMMARY) {
      endPoint = `/2.0/${constants.SUMMARY}/fetch`;
    } else if (type === constants.QUIZ_VALIDATE) {
      endPoint = `/${enableNewValidateAnswer ? '3.0' : '2.0'}/${constants.QUIZ}/validate/answer`;
    } else if (type === constants.NEXT_QUESTIONS) {
      endPoint = '/2.0/quiz/fetch/nextquestions';
    } else if (type === constants.GENERATE_TOPIC) {
      this.berlinClient.defaults.timeout = payload.topicTimeOut || 2000;
      endPoint = '/2.0/generate/topic';
    }
    this.berlinClient.interceptors.request.use(getRequestInterceptor());
    this.berlinClient.interceptors.response.use(getResponseInterceptor());
    let customHeaderParams = { ...headerParams };
    if (customHeaderParams.headers) {
      customHeaderParams.headers.Authorization = `Bearer ${token}`;
    } else {
      customHeaderParams = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return new Promise((resolve, reject) => {
      this.berlinClient.post(`${endPoint}`, payload, customHeaderParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Fetch User History Data based on ThreadId
  getChatHistory(payload, token, callback) {
    this.berlinClient.defaults.timeout = 180000;
    const {
      threadId,
      limit,
      offset,
      bookId
    } = payload;
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.get(`/2.0/aichathistory?bookId=${bookId}&threadId=${threadId}&limit=${limit}&offset=${offset}&sortByDate=DESC`, headerParams).then(
        (response) => {
          globalContext.threadId = threadId;
          globalContext.newThread = true;
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Fetch User History Data
  getUserChatHistory(payload, token, callback) {
    this.berlinClient.defaults.timeout = 180000;
    const {
      limit, offset, bookId, search
    } = payload;
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      const threadUrl = search ? `/1.0/aichathistoryThreads?bookId=${bookId}&limit=${limit}&offset=${offset}&search=${search}` : `/1.0/aichathistoryThreads?bookId=${bookId}&limit=${limit}&offset=${offset}`;
      this.berlinClient.get(threadUrl, headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Feeback API Service
  postFeedbackData(payload, hasFeedbackId, token, callback) {
    this.berlinClient.defaults.timeout = 180000;
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      if (hasFeedbackId !== '') {
        this.berlinClient.put('/2.0/feedback', payload, headerParams).then(
          (response) => {
            resolve(response.data);
            callback(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        this.berlinClient.post('/2.0/feedback', payload, headerParams).then(
          (response) => {
            resolve(response.data);
            callback(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  // Feeback API Service
  postFlashCardData(payload, isSummary, tenantObj, token, callback) {
    this.berlinClient.defaults.timeout = 180000;
    const headerParams = {
      headers: {
        'x-tenantid': tenantObj.tenantId,
        'x-tenantkey': tenantObj.tenantKey,
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      if (isSummary) {
        this.berlinClient.post('/2.0/flashcard/summary/generate', payload, headerParams).then(
          (response) => {
            resolve(response.data);
            callback(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        this.berlinClient.post('/2.0/flashcard/quiz/generate', payload, headerParams).then(
          (response) => {
            resolve(response.data);
            callback(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  getThreadTitle(payLoad, token, callback) {
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.get(`/1.0/getThreadTitle/thread/${payLoad.threadId}`, headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  renameChatHistoryTitle(payload, headers, callback) {
    const headerParams = {
      headers: {
        'thread-id': headers.threadId,
        Authorization: `Bearer ${headers.token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.put('/1.0/chat/history/thread/title', payload, headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteChatHistoryTitle(headers, callback) {
    const headerParams = {
      headers: {
        'thread-id': headers.threadId,
        Authorization: `Bearer ${headers.token}`,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.delete('/1.0/chat/history', headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  clearChatHistory(payload, token, callback) {
    globalContext.threadId = null;
    globalContext.newThread = false;
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-user-id': globalContext.userId
      },
      data: payload
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.delete('/2.0/user/chat/history', headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getRecommendations(payLoad, token, callback) {
    const headerParams = {
      headers: {
        'tenant-id': payLoad.tenantId,
        Authorization: `Bearer ${token}`,
        isStream: true,
        'x-user-id': globalContext.userId
      }
    };
    return new Promise((resolve, reject) => {
      this.berlinClient.get(`/1.0/recommendations?bookId=${payLoad.bookId}&pageId=${payLoad.pageId}&indexId=${payLoad.indexId}`, headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
