import { shapes } from '@greenville/framework';
import { makeStyles } from '@material-ui/core';
import { ChatComponent } from '@vega/aitutor';
import axios from 'axios';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import chapterList from '../data/chapterList.json';
import GetPiTokenModel from '../models/GetPiTokenModel';

const useStyles = makeStyles({
  heading: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    top: '13%',
    fontSize: '18px',
    fontWeight: '600'
  },
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    height: 'calc(100% - 64px)'
  }
});

const DynamicAIStudyTestComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { tenantId, tenantKey, tutorName, expandMode, berlinEnv, message, userId, courseId, productModel, pageId } =
    history.location.state;
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [tokenExpireTime, setTokenExpireTime] = useState(null);
  const [tokenValue, setTokenValue] = useState('');

  const getPiTokenApiCall = async () => {
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_PI_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const { token, expiresIn } = response.data.data;
      setUserToken(token);
      setTokenExpireTime(expiresIn);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setTokenValue(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tokenValue)) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 500);
      getPiTokenApiCall();
    }
  }, [tokenValue]);
  /* eslint-disable */

  useEffect(() => {
    if (!tokenExpireTime) return;
    const timeLeft = tokenExpireTime - Date.now() - 59000;
    const timeOutId = setTimeout(() => {
      getPiTokenApiCall();
    }, timeLeft);
    return () => clearTimeout(timeOutId);
  }, [tokenExpireTime]);

  /* eslint-disable */
  const Users = {
    username: 'Vignesh Selvam',
    initials: 'VS',
    userId: userId,
    token: userToken,
    isFirstTimeAITutorUser: false
  };

  const getAuthToken = (callback) => {
    const tokenConfig = {
      id: 'token',
      token: userToken
    };
    return new Promise((resolve) => {
      resolve(callback(tokenConfig.token));
    });
  };

  const handleChatbotOpened = (event) => {
    console.log('handleChatbotOpened callback', event);
  };

  const customConfig = {
    // floatingIcon: {
    //   toolTipName: 'Ask me about anything in your eTextbook',
    //   toolTipPlacement: 'left-center'
    // },
    // contentDetails: {
    //   chapterName: 'Chapter 10: Photosynthesis',
    //   sectionName: 'Concept 10.4: The Calvin cycle uses the chemical energy of ATP and NADPH to reduce CO2 to sugar'
    // },
    // headTag: {
    //   show: true, // true is default
    //   text: 'Beta'
    // },
    cannedMessages: {
      appHeading: tutorName,
      // appFooter:
      //   'PVS-AH Please don’t share personal data, and keep in mind that AI can be inaccurate. For more info, see our',
      // appFooterFAQLink: 'PVS-AH FAQs',
      // bookTitlePreText: "PVS-AH I'm here to help with anything related to ",
      // bookTitleSubText: '. PVS-AH Make a request below or choose from these options',
      welcome: message,
      welcomeBack: 'PVS-AH Welcome back!'
      // autoOpenHeadingMessage: 'PVS-AH Hi there! What can I help you with today?',
      // autoOpenWelcomeMessage: 'PVS-AH You can also try one of these common requests:',
      // newTopic: 'PVS-AH New topic it is!',
      // newTopicSubMessage: 'PVS-AH What can I help you with next?',
      // recommendationsHeading: 'PVS-AH Do you want me to explain a key topic from this page?',
      // ctaButtonHeadingWithOutRecommendationPills: 'PVS-AH Try one of these common requests:',
      // ctaButtonHeadingWithRecommendationPills: 'PVS-AH Or try one of these common requests:',
      // ctaButtonHeadingQuizStopMode: 'PVS-AH What can I help with next?',
      // summarizeOptionsHeading: 'PVS-AH Sure. What should I summarize?',
      // practiceOptionsHeading: 'PVS-AH Sure. Where should I pull those questions from?',
      // practiceOptionsTypeHeading: 'PVS-AH What kind of questions are you looking for?',
      // explainHeading: 'PVS-AH What topic or term from your eTextbook would you like explained?',
      // summaryButtonUserMessage: 'PVS-AH Can you summarize something for me?',
      // practiceButtonUserMessage: 'PVS-AH Can you generate some practice questions?',
      // explainButtonUserMessage: 'PVS-AH I need something explained'
    }
  };

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.heading}>{tutorName ? `Welcome to ${tutorName}` : 'Welcome to AI Study Tool'}</div>

      <ChatComponent
        customConfig={customConfig}
        isPageLoaded={isPageLoaded} // Required
        env={berlinEnv} // {env.ENVIRONMENT} // Required
        users={Users} // Required
        channelsVideoEsScore="1"
        theme="default" // Required
        masterTheme="plus" // Required
        locale="en-us" // Optional
        context={{
          bookTitle: 'Campbell Biology, 12e', // Required
          productModel: productModel, // 'EXTERNAL_PVS' Required
          indexId: '0fc5534dbbeaad9b8dbdbdaa9ff4a74f',
          printPageNumber: '5', // Optional
          pageId: pageId, // 'f92ced50-31ca-11ee-85d8-c7c9b8fd0d09',
          bookID: courseId, // 'BRNT-XUF8BS197', // Required
          isPpmTextExtracted: false, // Optional
          secondaryBookId: 'd9096892-1923-4685-ab27-b6c32dd9455d', // Required
          chapterList, // Required
          chapterId: 'cfc05d6e0-31ca-11ee-8eb0-d709f5427093', // Required
          chapterTitle: 'Chapter 4: Carbon and the Molecular Diversity of Life', // Required
          chapterNumber: 19, // Required
          contentType: 'PXE', // Required
          tenantObj: {
            tenantId: tenantId,
            tenantKey: tenantKey
          },
          berlinTenantObject: {
            tenantId: tenantId,
            tenantKey: tenantKey
          },
          xUserId: Users?.userId
        }} // Required
        getAuthToken={getAuthToken} // Required
        handleChatbotOpened={handleChatbotOpened}
        openBotContainer={open} // Required
        showChatBotIcon
        showUserHistory
        expandMode={expandMode}
        toggleBotContainer={() => setOpen(!open)}
      />
    </div>
  );
};

DynamicAIStudyTestComponent.propTypes = {
  getPiToken: shapes.modelOf(GetPiTokenModel).isRequired
};

export default observer(inject('getPiToken')(DynamicAIStudyTestComponent));
