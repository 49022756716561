import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { shapes } from '@greenville/framework';
import ReportDownloadModel from '../../models/DownloadReport';

const ReportDownloadButton = ({ filters, reportDownloadModel }) => {
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]); // Emails array for user input
  const [emailInput, setEmailInput] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleGenerateOrDownload = () => {
    setModalOpen(true);
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddEmail = () => {
    if (!isValidEmail(emailInput.trim())) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (emails.includes(emailInput.trim())) {
      setErrorMessage('This email is already added.');
      return;
    }
    setEmails([...emails, emailInput.trim()]);
    setEmailInput('');
    setErrorMessage(''); // Clear error
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleSendReport = async () => {
    const validEmails = emails.filter((email) => email && email.trim());

    setLoading(true);
    try {
      // Only include `email` key if valid emails exist
      const payload = {
        ...filters,
        ...(validEmails.length > 0 ? { email: validEmails } : {})
      };

      await reportDownloadModel.generateReport(payload);
      setModalOpen(false);
    } catch (error) {
      setErrorMessage('An error occurred while generating the report.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateOrDownload}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          sx={{
            width: '180px',
            textTransform: 'uppercase',
            fontSize: '12px',
            borderRadius: '30px',
            backgroundColor: '#005d83',
            '&:hover': {
              backgroundColor: '#003558'
            }
          }}
        >
          {loading ? 'Processing...' : 'Generate Report'}
        </Button>
      </Box>

      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: '400px'
          }}
        >
          <Typography variant="h6" mb={2}>
            Add Email Address
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={3}>
            Add the email addresses to send the report. You can include multiple recipients.
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <TextField
              label="Enter Email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#005d83'
                  },
                  '&:hover fieldset': {
                    borderColor: '#003558'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#003558'
                  }
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#003558'
                }
              }}
            />
            <IconButton
              onClick={handleAddEmail}
              color="primary"
              disabled={!emailInput.trim()}
              sx={{
                backgroundColor: '#005d83',
                width: 40,
                height: 40,
                borderRadius: '50%',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003558'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: '#a0a0a0'
                },
                marginTop: '8px'
              }}
            >
              <Add />
            </IconButton>
          </Box>

          {errorMessage && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errorMessage}
            </Typography>
          )}

          <List>
            {emails.map((email, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleRemoveEmail(email)}>
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText primary={email} />
              </ListItem>
            ))}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: '#ffffff',
                color: '#005d83',
                border: '1px solid #005d83',
                borderRadius: '30px',
                textTransform: 'uppercase',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  color: '#003558'
                }
              }}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#005d83',
                color: 'white',
                borderRadius: '30px',
                textTransform: 'uppercase',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: '#003558'
                }
              }}
              onClick={handleSendReport}
            >
              Send Report
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ReportDownloadButton.propTypes = {
  filters: PropTypes.object.isRequired,
  reportDownloadModel: shapes.modelOf(ReportDownloadModel).isRequired
};

export default inject('reportDownloadModel')(observer(ReportDownloadButton));
