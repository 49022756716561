import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../common/Loader';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import TenantsListModel from '../../models/TenantsList';
import TenantConfigureDialog from './TenantConfigureDialog';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  }
});

const TenantListView = (props) => {
  const { tenantsList, GetTenantListServiceStatus } = props;
  const classes = useStyles();
  const [tenantData, setTenantData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedInputValue, setSelectedInputValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const fethTenantsList = () => {
    tenantsList.fetch({});
    Framework.getEventManager().on(constants.SET_TENANT_LIST_DATA, () => {
      const { data } = tenantsList;
      setTenantData(toJS(data));
    });
  };

  useEffect(() => {
    fethTenantsList();
  }, []);

  const onRowClick = (val) => {
    setIsEdit(true);
    setOpenDialog(true);
    setSelectedInputValue(val);
  };

  const handleConfigureNewTenant = () => {
    setIsEdit(false);
    setOpenDialog(true);
    setSelectedInputValue({});
  };

  const handleClose = () => {
    setOpenDialog(false);
    fethTenantsList();
  };

  return (
    <LoadingHandler
      loading={GetTenantListServiceStatus.isPending}
      loadingContent={<Loader />}
      content={
        <>
          <Paper style={{ padding: '10px' }}>
            <Grid container spacing={2} style={{ flexDirection: 'column' }}>
              <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '30px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleConfigureNewTenant}
                  className={classes.button}
                >
                  Configure New Tenant
                </Button>
              </Box>
            </Grid>
            <Box sx={{ p: 1 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                Tenants
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <TableComponent
                columns={ColumnConfig.TenantsDataGridColumn}
                data={tenantData}
                onColumnClick={() => {}}
                order="ASC"
                onRowClick={onRowClick}
                directionValue=""
                userEmailDetails=""
                onMouseEnter={() => {}}
              />
            </Box>
          </Paper>
          {openDialog && (
            <TenantConfigureDialog
              openDialog={openDialog}
              handleClose={handleClose}
              isEdit={isEdit}
              selectedInputValue={selectedInputValue}
            />
          )}
        </>
      }
    />
  );
};

TenantListView.propTypes = {
  tenantsList: shapes.modelOf(TenantsListModel).isRequired,
  GetTenantListServiceStatus: shapes.state.isRequired
};

export default inject('tenantsList', 'GetTenantListServiceStatus')(observer(TenantListView));
