import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const providerListData = types.model('providerListData', {
  providerId: types.maybeNull(types.string),
  providerName: types.maybeNull(types.string),
  providerType: types.maybeNull(types.string),
  host: types.maybeNull(types.string),
  uri: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  port: types.maybeNull(types.string),
  timeout: types.maybeNull(types.union(types.string, types.integer, types.number)),
  keyName: types.maybeNull(types.string),
  valueLocation: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  capacity: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

const ProvidersListModel = types
  .model('ProvidersListModel', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(providerListData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_PROVIDERS_LIST_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_PROVIDERS_LIST_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_PROVIDERS_LIST_DATA);
    }
  }));

export default ProvidersListModel;
