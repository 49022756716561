import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  providerName: types.maybeNull(types.string),
  providerIds: types.optional(types.array(types.string), [])
});

const promptObj = types.model('promptObj', {
  display_name: types.maybeNull(types.string),
  db_field: types.maybeNull(types.string)
});

const featureObj = types.model('featureObj', {
  db_field: types.maybeNull(types.string),
  display_name: types.maybeNull(types.string),
  prompt_types: types.maybeNull(types.array(types.optional(promptObj, {})))
});

const tenantsListData = types.model('tenantsListData', {
  tenantId: types.maybeNull(types.string),
  tenantName: types.maybeNull(types.string),
  llm: types.maybeNull(types.array(types.optional(roleObj, {}))),
  contentFilter: types.maybeNull(types.array(types.optional(roleObj, {}))),
  textEmbedding: types.maybeNull(types.array(types.optional(roleObj, {}))),
  tenantKey: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  feature: types.maybeNull(types.array(types.optional(featureObj, {}))),
  isActive: types.union(types.boolean, types.string),
  productModel: types.maybeNull(types.string)
});

const TenantsListModel = types
  .model('TenantsListModel', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(tenantsListData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_TENANT_LIST_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_TENANT_LIST_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_TENANT_LIST_DATA);
    }
  }));

export default TenantsListModel;
