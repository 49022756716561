import { BaseService } from '@greenville/framework';
import axios from 'axios';
import * as constants from '../../../common/constants';

export default class ReportS3Service extends BaseService {
  constructor() {
    super(constants.REPORT_DOWNLOAD_SERVICE, 'ReportS3Service');
  }

  async getCustomPreSignedUrl(fileId) {
    const response = await axios.get(`/chatgptutility/reports/download/${fileId}`, {
      responseType: 'blob'
    });
    return this.handleResponse(response);
  }

  handleResponse(response) {
    if (response && response.data && response.data.signedUrl) {
      return response.data.signedUrl;
    }
    throw new Error('Download link not found.');
  }

  handleError(error) {
    throw new Error(error.response?.data?.message || 'Download link not found.');
  }
}
