import { types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const ReportDownloadModel = types
  .model('ReportDownload', {
    errorMessage: types.maybeNull(types.string),
    successMessage: types.maybeNull(types.string),
    isReportGenerated: types.boolean
  })
  .actions(() => ({
    generateReport(payload) {
      Framework.getEventManager().publish(constants.REPORT_DOWNLOAD_SQS_SERVICE, { payload });
    }
  }));

export default ReportDownloadModel;
