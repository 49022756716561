import * as constants from '../constants';

const ChannelsDataGridColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'question',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const ChannelsListingDataGridColumn = [
  {
    key: 'dateStamp',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userCommand',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const EffectivenessLatestDataGridColumn = [
  {
    key: 'input',
    name: 'User Input',
    columnWidth: '250px'
  },
  {
    key: 'output',
    name: 'AI Study Tool Response',
    columnWidth: '300px'
  },
  {
    key: 'gpt_relevance',
    name: 'Relevance',
    columnWidth: '50px'
  },
  {
    key: 'gpt_coherence',
    name: 'Coherence',
    columnWidth: '50px'
  },
  {
    key: 'gpt_groundedness',
    name: 'Groundedness',
    columnWidth: '50px'
  },
  {
    key: 'gpt_fluency',
    name: 'Fluency',
    columnWidth: '50px'
  },
  {
    key: 'gpt_similarity',
    name: 'Similarity',
    columnWidth: '50px'
  },
  {
    key: 'f1_score',
    name: 'F1 Score',
    columnWidth: '50px'
  }
];

const EffectivenessDataGridColumn = [
  {
    key: 'input',
    name: 'User Input',
    columnWidth: '250px'
  },
  {
    key: 'output',
    name: 'AI Study Tool Response',
    columnWidth: '300px'
  },
  {
    key: 'Answer Relevance',
    name: 'Answer Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Context Relevance',
    name: 'Context Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Correctness',
    name: 'Correctness',
    columnWidth: '50px'
  },
  {
    key: 'Groundedness',
    name: 'Groundedness',
    columnWidth: '50px'
  }
];
const AdminPromptsDataGridColumn = [
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '150px'
  },
  {
    key: 'promptStart',
    name: 'Prompt Start',
    columnWidth: '150px'
  },
  {
    key: 'promptEnd',
    name: 'Prompt End',
    columnWidth: '150px'
  },
  {
    key: 'version',
    name: 'Version',
    columnWidth: '50px'
  },
  {
    key: 'status',
    name: 'Status',
    columnWidth: '50px'
  },
  {
    key: 'createdDate',
    name: 'Date Created',
    columnWidth: '50px'
  },
  {
    key: 'updatedDate',
    name: 'Date Updated',
    columnWidth: '50px'
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
    columnWidth: '50px'
  }
];

const TenantsDataGridColumn = [
  {
    key: 'tenantName',
    name: 'Name',
    columnWidth: '100px'
  },
  {
    key: 'tenantId',
    name: 'Tenant ID',
    columnWidth: '100px'
  },
  {
    key: 'tenantKey',
    name: 'Tenant key',
    columnWidth: '100px'
  },
  {
    key: 'updatedBy',
    name: 'Updated By',
    columnWidth: '150px'
  }
];

const promptConfig = [
  {
    role: 'user',
    content: ''
  },
  {
    role: 'system',
    content: ''
  },
  {
    role: 'assistant',
    content: ''
  }
];

const CommonDataGridColumn = (param) => [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '190px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'question',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const CommonFeedbackColumn = (param) => [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '160px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.TITLE,
    columnWidth: '100px'
  },
  {
    key: 'feedback',
    name: 'Feedback',
    columnWidth: '150px'
  },
  {
    key: 'reasonForDisLike',
    name: 'Reason for dislike',
    columnWidth: '150px'
  },
  {
    key: 'comments',
    name: 'Comments',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const CommonGridListingColumn = (param) => [
  {
    key: 'dateStamp',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '190px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '160px'
  },
  {
    key: 'title',
    name: constants.TITLE,
    columnWidth: '100px'
  },
  {
    key: 'userCommand',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.CHAT_USERLOGIN,
    columnWidth: '100px'
  }
];

const CommonTrendsDataGridColumn = (param) => [
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '100px'
  },
  {
    key: 'date',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '100px'
  },
  {
    key: 'likeCount',
    name: constants.GPT_TRENDS_LIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'disLikeCount',
    name: constants.GPT_TRENDS_DISLIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'explainCount',
    name: constants.GPT_TRENDS_EXPLAINCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'summaryCount',
    name: constants.GPT_TRENDS_SUMMARYCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'quizMcqCount',
    name: constants.GPT_TRENDS_QUIZCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unknownCount',
    name: constants.GPT_TRENDS_UNKNOWNCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'blockWordsCount',
    name: constants.GPT_TRENDS_BLOCKEDWORDCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredCount',
    name: constants.GPT_TRENDS_UNANSWEREDCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoCSGDocCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredUnknownIntentCount',
    name: constants.GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoTopicFoundCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredProblemSolveCount',
    name: constants.GPT_TRENDS_BLOCKED_COUNT,
    columnWidth: '100px'
  }
];

const PVSAssessmentGridColumns = [
  {
    key: 'assessmentName',
    name: 'Assessment Name',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '120px'
  },
  {
    key: 'id',
    name: 'ID',
    columnWidth: '100px'
  },
  {
    key: 'studentsAssigned',
    name: 'Students Assigned',
    columnWidth: '100px'
  },
  {
    key: 'teachers',
    name: 'Teachers',
    columnWidth: '100px'
  },

  {
    key: 'created',
    name: 'Created',
    columnWidth: '100px'
  },
  {
    key: 'lastModified',
    name: 'Last Modified',
    columnWidth: '100px'
  },
  {
    key: 'status',
    name: 'Status',
    columnWidth: '100px'
  }
];

const AIStudyChartsFeedbackColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '150px'
  },
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'feedback',
    name: 'Feedback',
    columnWidth: '150px'
  },
  {
    key: 'reasonForDisLike',
    name: 'Reason for dislike',
    columnWidth: '150px'
  },
  {
    key: 'comments',
    name: 'Comments',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsViolenceColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.GPT_TRENDS_BOOKTITLE,
    columnWidth: '150px'
  },
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'violenceCategory',
    name: 'Violence',
    columnWidth: '150px'
  },
  {
    key: 'violenceSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsHateColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.GPT_TRENDS_BOOKTITLE,
    columnWidth: '150px'
  },
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'hateCategory',
    name: 'Hate',
    columnWidth: '150px'
  },
  {
    key: 'hateSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsSelfHarmColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.GPT_TRENDS_BOOKTITLE,
    columnWidth: '150px'
  },
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'selfharmCategory',
    name: 'Self Harm',
    columnWidth: '150px'
  },
  {
    key: 'selfharmSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsSexualColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.GPT_TRENDS_BOOKTITLE,
    columnWidth: '150px'
  },
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'sexualCategory',
    name: 'Sexual',
    columnWidth: '150px'
  },
  {
    key: 'sexualSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIStudyTrendsDataGridColumn = [
  {
    key: 'title',
    name: constants.GPT_TRENDS_BOOKTITLE,
    columnWidth: '100px'
  },
  {
    key: 'bookId',
    name: 'Book Id',
    columnWidth: '100px'
  },
  {
    key: 'likeCount',
    name: constants.GPT_TRENDS_LIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'disLikeCount',
    name: constants.GPT_TRENDS_DISLIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'explainCount',
    name: constants.GPT_TRENDS_EXPLAINCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'summaryCount',
    name: constants.GPT_TRENDS_SUMMARYCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'quizMcqCount',
    name: constants.GPT_TRENDS_QUIZCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unknownCount',
    name: constants.GPT_TRENDS_UNKNOWNCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredCount',
    name: constants.GPT_TRENDS_UNANSWEREDCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoCSGDocCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredUnknownIntentCount',
    name: constants.GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoTopicFoundCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredProblemSolveCount',
    name: 'Un-Answered (Problem Solve) Count',
    columnWidth: '100px'
  }
];

const ChannelsTrendsDataGridColumn = [
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '100px'
  },
  {
    key: 'videoSummaryCount',
    name: constants.GENERATE_VIDEO_SUMMARY_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'questionIntentCount',
    name: constants.GENERATE_QUESTION_INTENT_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'questionSolutionCount',
    name: constants.GENERATE_QUESTION_SOLUTION_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'questionStepsSolutionCount',
    name: constants.QUESTION_STEP_SOLUTION_COUNT,
    columnWidth: '100px'
  }
];

const ProvidersDataGridColumn = [
  {
    key: 'providerId',
    name: 'Provider ID',
    columnWidth: '100px'
  },
  {
    key: 'providerName',
    name: 'Provider Name',
    columnWidth: '100px'
  },
  {
    key: 'keyName',
    name: 'Key Name',
    columnWidth: '100px'
  },
  {
    key: 'valueLocation',
    name: 'Value Location',
    columnWidth: '100px'
  },
  {
    key: 'host',
    name: 'Host',
    columnWidth: '100px'
  },
  {
    key: 'uri',
    name: 'URI',
    columnWidth: '100px'
  },
  {
    key: 'region',
    name: 'Region',
    columnWidth: '100px'
  }
];

const ColumnConfig = {
  ChannelsDataGridColumn,
  ChannelsListingDataGridColumn,
  EffectivenessDataGridColumn,
  AdminPromptsDataGridColumn,
  TenantsDataGridColumn,
  promptConfig,
  CommonDataGridColumn,
  CommonFeedbackColumn,
  CommonGridListingColumn,
  CommonTrendsDataGridColumn,
  PVSAssessmentGridColumns,
  AIStudyChartsFeedbackColumn,
  AIContentFilterChartsSexualColumn,
  AIContentFilterChartsSelfHarmColumn,
  AIContentFilterChartsHateColumn,
  AIContentFilterChartsViolenceColumn,
  EffectivenessLatestDataGridColumn,
  AIStudyTrendsDataGridColumn,
  ChannelsTrendsDataGridColumn,
  ProvidersDataGridColumn
};

export default ColumnConfig;
