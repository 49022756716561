import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const TenantProviderDialog = ({ open, title, fields, handleClose, handleSave, dialogType }) => (
  <BootstrapDialog open={open} onClose={handleClose} PaperProps={{ style: { width: '80%' } }}>
    <DialogTitle>{title}</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500]
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers style={{ textAlign: 'center' }}>
      {fields.map((field, index) => (
        <TextField
          key={index}
          margin="dense"
          label={field.label}
          type="text"
          multiline
          fullWidth
          rows={2}
          variant="outlined"
          value={field.value}
          onChange={field.onChange}
          required
        />
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="outlined" size="small" color="primary">
        Cancel
      </Button>
      <Button
        onClick={() => handleSave(dialogType)}
        variant="outlined"
        size="small"
        color="primary"
        disabled={fields.some((field) => _.isEmpty(field.value))}
      >
        Save
      </Button>
    </DialogActions>
  </BootstrapDialog>
);

TenantProviderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  dialogType: PropTypes.string.isRequired
};

export default TenantProviderDialog;
