/* eslint-disable */
import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Button,
  Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Utilities } from '@aquila/core';
import PositiveFeedback from '../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../Components/FeedbackComponent/NegativeFeedback';
import MoreMenuIcon from '../Common/icons/MoreMenuIcon';
import LikeIcon from '../Common/icons/LikeIcon';
import LikeIconFilled from '../Common/icons/LikeIconFilled';
import DisLikeIcon from '../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../Common/icons/DisLikeIconFilled';
import messages from '../defaultMessages';
import CopyIcon from '../Common/icons/CopyIcon';
// import RegenerateIcon from '../Common/icons/RegenerateIcon';
import { eventAction, gaEvents } from '../analytics/analytics';
import {
  clickMoreActionsGAEvent,
  userClicksOptOutFeature,
  userSeesOptOutFeature
} from '../analytics/analyticsEvents';
import ThemeConstants from '../Common/ThemeConstants';
import * as constants from '../Common/Constants';
import NoteIcon from '../Common/icons/NoteIcon';
import ReadMore from './Summary/ReadMore';
import BulletIcon from '../Common/icons/BulletIcon';
import ParagraphIcon from '../Common/icons/ParagraphIcon';
import Utils from '../Common/Utils';
import { clickThumbsGAEvent } from '../analytics/analyticsEvents';
import VideoCardPlayer from '../Components/VideoCard/VideoCardPlayer';
import ChainIcon from '../Common/icons/ChainIcon';
import ImageParser from '../Common/parser/ImageParser';
import AnchorParser from '../Common/parser/AnchorParser';
import { getThemeValue } from '../Common/CustomStyles/customStyleUtils';
import CustomReferenceLinkMessage from './ClarifyContainer/CustomReferenceLinkMessage';
import useMoreActions from '../Components/Hooks/useMoreActions';
// import AnchorComponent from './AnchorComponent';

const EXPLAIN = 'explain';
const styles = () => ({
  moreIconStyle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  feedbackButton: {
    padding: 8
  },
  moreActionList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => (getThemeValue(props, 'menuList', 'bgColor', false, 'bgColor')),
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'menuList', 'textColor', false, 'textColor'))
  },
  regenerateStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    color: '#020917',
    marginRight: 5
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    background: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
    padding: 8,
    width: 'fit-content',
    borderRadius: '4px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  tooltipArrow: {
    color: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor'))
  },
  summaryContainer: {
    width: '100%',
    '& pre': {
      whiteSpace: 'normal'
    },
    '& table': {
      fontFamily: 'TT Commons',
      borderCollapse: 'collapse',
      width: 350,
      marginTop: 20,
      marginBottom: 20,
      overflowX: 'auto',
      display: 'block'
    },
    '& td': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: 8,
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    },
    '& th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: 8,
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    },
    '& tr:nth-child(even)': {
      backgroundColor: props => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgColor
    }
  },
  moreActionListContainer: {
    padding: 0
  },
  listItemRootStyle: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0
  },
  listAlignment: {
    marginBottom: 0
  },
  descriptionStyle: {
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  orderList: {
    fontSize: '16px',
    paddingInlineStart: 20,
    margin: 0,
    lineHeight: '24px',
    listStyleType: 'disc',
    '& li': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
      marginBottom: 5
    }
  },
  formatWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formatStyle: {
    color: props => ThemeConstants[props.masterTheme][props.theme].formatColor,
    fontSize: 14,
    fontFamily: 'TT Commons'
  },
  alignmentRight: {
    textAlign: 'right'
  },
  explainHeader: {
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    lineHeight: '35px',
    display: 'block'
  },
  listItemWrapper: {
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontSize: 16,
      fontStyle: 'normal',
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '20px',
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    },
    '& ul': {
      fontSize: 16,
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'disc',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
        marginBottom: 5
      }
    },
    '& ol': {
      fontSize: 16,
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'decimal',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
        marginBottom: 5,
        display: 'list-item'
      }
    }
  },
  showMoreActionWrapper: {
    marginTop: 15,
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%'
  },
  buttonStyle: {
    height: '32px',
    width: '50%',
    borderRadius: '8px',
    backgroundColor: props => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgColor,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    padding: 6,
    border: props => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBorderColor,
    '&:hover': {
      backgroundColor: props => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgHoverColor,
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    }
  },
  alignRight: {
    marginRight: 12
  },
  videoWrapper: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    marginBottom: 16
  },
  videoOuterWrapper: {
    width: 350,
    height: 264,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#F7F9FD',
    border: '1px solid #E8EEFA'
  },
  videoInnerWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  },
  footerWrapper: {
    height: 72,
    position: 'relative',
    padding: '26px 19px',
    boxSizing: 'border-box'
  },
  footerTitle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 18,
    lineHeight: '20px',
    color: '#020917',
    margin: 0,
    padding: 0,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
    overflow: 'hidden'
  },
  durationWraper: {
    position: 'absolute',
    bottom: 92,
    right: 10,
    zIndex: 1,
    display: 'flex',
    padding: '4px 6px',
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    alignItems: 'center'
  },
  durationStyle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    margin: 0,
    padding: '0px 0px 0px 6px',
    color: '#020917'
  },
  explainQuizActionWrapper: {
    marginTop: 15,
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%'
  },
  quizDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    margin: '16px 0px 16px 0px',
    display: 'block',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  linkWrapper: {
    textAlign: 'right',
    margin: '5px 0 5px'
  },
  channelLinkStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor')),
    textDecoration: 'underline',
    '&:hover': {
      color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor'))
    }
  },
  tooltipWrapperStyle: {
    fontFamily: 'TT Commons',
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: '#161C29',
    color: '#FEFEFE',
    border: '1px solid #161C29',
    marginLeft: '-5px',
    maxWidth: 300,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    padding: 5,
    alignItems: 'center',
    borderRadius: 6,
    '&::after': {
      position: 'absolute',
      top: 29,
      left: 12,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: '#161C29 transparent transparent transparent',
      content: 'close-quote'
    }
  },
  toolipTitleStyle: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 3,
    color: '#FEFEFE'
  },
  pageStyle: {
    maxWidth: 60,
    fontFamily: 'TT Commons',
    fontSize: 14,
    fontWeight: 400,
    marginRight: 3,
    color: '#FEFEFE'
  },
  fullWidth: {
    width: '100%'
  }
});
const StreamingDiscuss = ({
  classes,
  intl,
  botResponse,
  discussData,
  handleChatGPTCustomNote,
  onRegenerate,
  chapterId,
  chapterTitle,
  handleGAEvents,
  handleSubmitFeedback,
  onCopy,
  theme,
  masterTheme,
  showFormat,
  onFormatChange,
  feedbackIconFocusId,
  inputValue,
  explainFlowMessage,
  responseOptionType,
  onSend,
  onMove,
  disableOptions,
  bookTitle,
  bookId,
  videos,
  chatType,
  enableExplainQuizOptions,
  onExplainQuiz,
  onExplainMoveOn,
  explainQuizButton,
  initSuggestToQuizEvent,
  showExplainQuiz,
  channelId,
  env,
  videoRef,
  user,
  onLDTrack,
  isExpanded,
  hideChannelsPopout,
  pageList,
  onPageNavigation,
  showSourceReferencePage,
  playList,
  showOptoutLink,
  onHideChannelVideo,
  onShowChannelVideo,
  productId,
  isChannelsBundleSubscriber,
  isOptInLinkEnabled,
  initials,
  showPremiumVideo,
  showOptInPremiumMessage,
  showPractice,
  onClickCallback,
  intentType,
  isContentMedia,
  sourceLinkUrl,
  customConfig,
  customThemeStyles
}) => {
  const { formatMessage } = intl;
  const [showMoreActionOptions, setMoreActionOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [isFormat, setIsFormat] = useState(showFormat);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const [showSummaryButton, setShowSummaryButton] = useState(true);
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const buttonRef = useRef(null);
  const suggestionRef = useRef(null);
  const [isClickAway, setIsClickAway] = useState(false);
  const [showQuizButton, setShowQuizButton] = useState(true);
  const [toolTipData, setToolTipData] = useState({});
  let intervalData = null;
  const [formattedContent, setFormattedContent] = useState('');
  const [showReferenceLink, setShowReferenceLink] = useState(false);
  const [pageReferenceData, setPageReferenceData] = useState({});

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  const getPageTitle = (pageId) => {
    const filteredObject = playList.find(playListObject => playListObject.id === pageId);
    return filteredObject && filteredObject.title;
  };

  const getPageNo = (pageId) => {
    const filteredObject = playList.find(playListObject => playListObject.id === pageId);
    return filteredObject && filteredObject.pageNo;
  };

  const handleReferenceLink = (event) => {
    if (!sourceLinkUrl) return;
    const { dataset } = (event && event.target) || {};
    const pageid = dataset && dataset.pageid;
    setPageReferenceData({
      title: pageid ? getPageTitle(pageid) : '',
      pageId: pageid ? pageid : '',
    });
    if (!showReferenceLink) setShowReferenceLink(true);
  };

  const handleMouseOverCallback = () => {
    const { layerY } = event || {};
    const { dataset } = (event && event.target) || {};
    const pageid = dataset && dataset.pageid;
    const anchorElement = document.querySelectorAll(`a[data-pageid="${pageid}"]`);
    const offsetLeft = anchorElement && anchorElement[0] && anchorElement[0].offsetLeft;
    setToolTipData({
      left: offsetLeft,
      top: layerY - 65,
      title: pageid ? getPageTitle(pageid) : '',
      pageNo: pageid ? getPageNo(pageid) : ''
    });
  }
  const handleMouseLeaveCallback = () => {
    setToolTipData({});
  }
  const discussDataLoaded = () => {
    const elementId = `discuss_${discussData.requestId}`;
    if (document.getElementById(elementId)) {
      clearInterval(intervalData);
      AnchorParser.parse(elementId, '.citationlink', onClickCallback, handleMouseOverCallback, handleMouseLeaveCallback, handleReferenceLink);
      ImageParser.parse(elementId, onClickCallback);
    }
  };

  useEffect(() => {
    if (discussData && discussData.streamStatus === constants.DONE) {
      intervalData = setInterval(discussDataLoaded, 500);
    }
  }, [discussData && discussData.streamStatus]);

  useEffect(() => {
    const target = suggestionRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          initSuggestToQuizEvent();
          observer.unobserve(target);
        }
      });
    }, { threshold: 0.5 });

    if (target) {
      observer.observe(target);
    }

    // Cleanup function to unobserve when the component unmounts
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [discussData.streamStatus === constants.DONE]); // Empty dependency array ensures the effect runs once on mount

  useEffect(() => {
    if (discussData.streamStatus === constants.DONE && buttonRef && buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.focus();
      }, 500);
    }
    if (discussData.streamStatus === constants.DONE && !discussData.isCannedMessage && !discussData.useRectifier && discussData.category !== constants.CALCULATION && videos && videos.length) {
      if (showOptoutLink) userSeesOptOutFeature(handleGAEvents, bookId); // GA Event to see opt out link
      if (isOptInLinkEnabled) userSeesOptOutFeature(handleGAEvents, bookId, true); // GA Event to see opt in link
    }
  }, [discussData.streamStatus]);

  const processStreamingContent = async () => {
        const data = await Utilities.textFromContentV3(discussData?.content);
    const markedData = Utils.convertToMarkedDownString(data, true);
    setFormattedContent(markedData);
  };

  useEffect(() => {
    if(discussData?.content) {
      processStreamingContent();
    }
  }, [discussData?.content]);

  useLayoutEffect(() => {
    if(discussData.streamStatus === constants.DONE) {
      const clientWidth = (document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientWidth) || 400;
      const tableList = document.querySelectorAll('.aiDiscussContainer table');
      if (tableList && tableList.length) {
        tableList.forEach((element) => {
          element.style.width = `${clientWidth - 50}px`; // eslint-disable-line no-param-reassign
        });
      }
    }
  }, [isExpanded, discussData.streamStatus]);

  const onMoreActionClick = (event, isMoreActionDialogueOpen = false) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMoreActionOptions(!showMoreActionOptions);
    if (typeof isMoreActionDialogueOpen === 'boolean' && isMoreActionDialogueOpen) clickMoreActionsGAEvent(handleGAEvents, constants.GPT_EVENTS.EXPLAIN, bookId);
  };

  const text = useMemo(() => discussData.apiContent, [discussData.apiContent]);

  const handleCopy = () => {
    onMoreActionClick();
    if (discussData.requestId) {
      let copyText = document.getElementById(discussData.requestId).innerHTML;
      if(discussData.payloadType === constants.PROBLEM_SOLVE_RECTIFIER || discussData.payloadType === constants.PROBLEM_SOLVE_CALCULATION) {
        const problemSolveArray = document.querySelectorAll(`div[id="${discussData.requestId}"]`);
        if (problemSolveArray && problemSolveArray.length > 1){
          copyText = `${problemSolveArray[0].innerHTML} <div>${discussData.payloadType ===  constants.PROBLEM_SOLVE_RECTIFIER ? formatMessage(messages.problemSolveLoaderMessage) : ''}</div> ${problemSolveArray[1].innerHTML}`
        }
      }
      Utils.exportCopyText(copyText);
      onCopy();
    }
    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.EXPLAIN
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleNote = () => {
    onMoreActionClick();
    const parsedNoteText = Utils.convertToMarkedDownString(text, true);
    handleChatGPTCustomNote(parsedNoteText, chapterId, chapterTitle);
    const additionalFields = {
      event_label: 'Save as Note',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.EXPLAIN
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleRegenerate = () => {
    onRegenerate();
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    const option = botResponse.response && botResponse.response.option === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, isContentMedia ? constants.GPT_EVENTS.CONTENT_MEDIA : option, chatType);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    const option = botResponse.response && botResponse.response.option === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, isContentMedia ? constants.GPT_EVENTS.CONTENT_MEDIA : option, chatType);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    let payloadInfo = {
      type: (discussData && discussData.payloadType) || constants.DISCUSS,
      requestId: discussData.requestId,
      responseId: discussData.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: discussData.apiContent
    };
    setIsClickAway(false);
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    let feedbackType = constants.DISCUSS;
    if(intentType === constants.IMAGE_EXPLAIN_INTENT || intentType === constants.IMAGE_CYU_EVALUATE_INTENT || intentType === constants.IMAGE_QUESTION_INTENT) {
      feedbackType = intentType;
    } else if(discussData && discussData.payloadType) {
      feedbackType = discussData.payloadType;
    }
    const payloadInfo = {
      type: feedbackType,
      requestId: discussData.requestId,
      responseId: discussData.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: discussData.apiContent
    };
    setPostiveFeedbackFlag(true);
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   event_action: eventAction.providePositiveFeedback,
    //   input: value
    // };
    // gaEvents(handleGAEvents, additionalFields);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    let feedbackType = constants.DISCUSS;
    if(intentType === constants.IMAGE_EXPLAIN_INTENT || intentType === constants.IMAGE_CYU_EVALUATE_INTENT || intentType === constants.IMAGE_QUESTION_INTENT) {
      feedbackType = intentType;
    } else if(discussData && discussData.payloadType) {
      feedbackType = discussData.payloadType;
    }
    const payloadInfo = {
      type: feedbackType,
      requestId: discussData.requestId,
      responseId: discussData.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: discussData.apiContent
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  const handleFormatChange = () => {
    setIsFormat(false);
    onFormatChange((botResponse.response && botResponse.response.option === constants.BULLET) ? constants.PARAGRAPH : constants.BULLET, inputValue);
  };

  const handleShowMore = () => {
    gaEvents(handleGAEvents, {
      event_action: eventAction.clickChatContinuation,
      event_label: 'Tell me more',
      event_value: null,
      command_source_code: 'click'
    });
    setShowSummaryButton(false);
    onSend({}, inputValue, false, true, false, true, true);
  };
  const handleMoveOn = () => {
    setShowSummaryButton(false);
    onMove();
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };
  const handleExplainQuiz = () => {
    setShowQuizButton(false);
    onExplainQuiz();
  };

  const handleExplainMoveOn = () => {
    setShowQuizButton(false);
    onExplainMoveOn();
  };

  const handleHideChannelVideo = () => {
    onHideChannelVideo();
    userClicksOptOutFeature(handleGAEvents, '0', bookId);
  };
  const handleShowChannelVideo = () => {
    onShowChannelVideo();
    userClicksOptOutFeature(handleGAEvents, '1', bookId);
  };

  const { showCopy, showSaveAsNote } = useMoreActions(customConfig);
  const showMoreActionsIcon = useMemo(() => showCopy || showSaveAsNote, []);

  return (
    <Box className={`aiDiscussContainer ${classes.summaryContainer}`} aria-label={discussData.streamStatus && discussData.streamStatus !== constants.DONE ? 'Explanation streaming in progress' : 'Explanation streaming completed'}>
      <Box aria-hidden={discussData.streamStatus && discussData.streamStatus !== constants.DONE}>
        {/* {
        explainFlowMessage && (
          <Typography variant="span" className={classes.explainHeader}>{explainFlowMessage}</Typography>
        )
      } */}
      <div className={classes.listItemWrapper}>
      {
        formattedContent && (
          <ReadMore
            responseOptionType={responseOptionType}
            isStreamingMode streamStatus={discussData.streamStatus}
            summaryData={formattedContent}
            masterTheme={masterTheme}
            theme={theme}
            customThemeStyles={customThemeStyles}
            requestId={discussData.requestId}
            elementId={`discuss_${discussData.requestId}`}
          />
        )
      }
      </div>
      </Box>
      {
        /* (showSourceReferencePage && discussData.streamStatus && discussData.streamStatus === constants.DONE && pageList && pageList.length > 0 && chatType === EXPLAIN) && (
          <AnchorComponent
            pageList={pageList}
            playList={playList}
            onRedirection={onPageNavigation}
            handleGAEvents={handleGAEvents}
          />
        ) */
      }
      {
        (discussData.streamStatus && discussData.streamStatus === constants.DONE && !discussData.isCannedMessage && !discussData.useRectifier && discussData.category !== constants.CALCULATION) && (
          <div>
            {
              (videos && videos.length > 0) && (
                <VideoCardPlayer
                  premiumVideo={videos}
                  onLDTrack={onLDTrack}
                  user={user}
                  channelId={channelId}
                  env={env}
                  videoRef={videoRef}
                  theme={theme}
                  masterTheme={masterTheme}
                  hideChannelsPopout={hideChannelsPopout}
                  handleGAEvents={handleGAEvents}
                  bookId={bookId}
                  productId={productId}
                  initials={initials}
                  showPremiumVideo={showPremiumVideo}
                  showOptInPremiumMessage={showOptInPremiumMessage}
                  isChannelsBundleSubscriber={isChannelsBundleSubscriber}
                  customThemeStyles={customThemeStyles} 
                />
              )
            }
            {
              (showOptoutLink && videos && videos.length > 0) &&(
                <div className={classes.linkWrapper}>
                  <Link
                    component="button"
                    className={classes.channelLinkStyle}
                    variant="body2"
                    onClick={handleHideChannelVideo}
                  >
                    {
                      formatMessage(messages.dontShowChannelVideo)
                    }
                  </Link>
                </div>
              )
            }
            {
              (isOptInLinkEnabled && videos && videos.length > 0) &&(
                <div className={classes.linkWrapper}>
                  <Link
                    component="button"
                    className={classes.channelLinkStyle}
                    variant="body2"
                    onClick={handleShowChannelVideo}
                  >
                    {formatMessage(messages.showStudyAndExamPrepVideos)}
                  </Link>
                </div>
              )
            }
            <Box className={isFormat ? classes.actionContainer : classes.alignmentRight}>
              {
              isFormat && (
                <div className={classes.formatWrapper}>
                  <Typography variant="p" className={classes.formatStyle}>Format to</Typography>
                  <Tooltip
                    classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }}
                    title={(botResponse.response && botResponse.response.option === constants.BULLET) ? 'Paragraph' : 'Bullet points'}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      className={classes.feedbackButton}
                      onClick={handleFormatChange}
                    >
                      {
                        (botResponse.response && botResponse.response.option === constants.BULLET) ? <ParagraphIcon masterTheme={masterTheme} theme={theme} /> : <BulletIcon masterTheme={masterTheme} theme={theme} />
                      }
                    </IconButton>
                  </Tooltip>
                </div>
              )
            }
              {
                (intentType !== constants.IMAGE_CYU_INTENT) && (
                  <div>
                    <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
                      <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={event => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
                        {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <LikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
                      <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={event => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
                        {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                      </IconButton>
                    </Tooltip>
                    {showMoreActionsIcon && (<Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label={constants.MORE_ACTIONS} aria-haspopup title={formatMessage(messages.moreActions)} placement="top" arrow>
                      <IconButton className={classes.feedbackButton} onClick={event => onMoreActionClick(event, true)} aria-label={constants.MORE_ACTIONS}>
                        <MoreMenuIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                      </IconButton>
                    </Tooltip>)}
                    {/* <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Regenerate response" placement="top" arrow>
                <IconButton className={classes.feedbackButton} onClick={handleRegenerate}>
                  <RegenerateIcon />
                </IconButton>
              </Tooltip> */}
                  </div>
                )
              }
            </Box>
            {
              (!disableOptions && (discussData.hasMoreSummary && showSummaryButton)) && (
                <div className={classes.showMoreActionWrapper}>
                  <Button
                    buttonRef={buttonRef}
                    component="button"
                    onClick={handleShowMore}
                    className={`${classes.buttonStyle} ${classes.alignRight}`}
                  >
                    {formatMessage(messages.tellMore)}
                  </Button>
                  <Button
                    component="button"
                    onClick={handleMoveOn}
                    className={classes.buttonStyle}
                  >
                    {formatMessage(messages.moveOn)}
                  </Button>
                </div>
              )
            }
            { /** Enable CTA buttons for explain quiz follow options */
              enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_C && showExplainQuiz && showPractice && (
                <div ref={suggestionRef}>
                  <Typography variant="paragraph" className={classes.quizDescription}>
                    {formatMessage(messages.explainQuizDescription)}
                  </Typography>
                  {
                      (showQuizButton && explainQuizButton) && (
                        <div className={classes.explainQuizActionWrapper}>
                          <Button
                            component="button"
                            onClick={handleExplainQuiz}
                            className={`${classes.buttonStyle} ${classes.alignRight}`}
                          >
                            {formatMessage(messages.explainQuizMe)}
                          </Button>
                          <Button
                            component="button"
                            onClick={handleExplainMoveOn}
                            className={classes.buttonStyle}
                          >
                            {formatMessage(messages.summaryMoveOn)}
                          </Button>
                        </div>
                      )
                    }
                </div>
              )
            }
          </div>
        )
      }
      {
        showMoreActionOptions && (
          <Menu
            id="moreActionOptions"
            open={showMoreActionOptions}
            anchorEl={anchorEl}
            classes={{ paper: classes.moreActionList, list: classes.moreActionListContainer }}
            className={classes.poperStyle}
            keepMounted
            onClose={onMoreActionClick}
          >
            {showSaveAsNote && (<MenuItem
              button
              onClick={handleNote}
              classes={{
                    root: classes.listItemRootStyle
                  }}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <NoteIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.saveNote)}
              </ListItemText>
            </MenuItem>)}
            {showCopy && (<MenuItem
              button
              classes={{
                root: `${classes.listItemRootStyle} ${classes.listAlignment}`
              }}
              onClick={handleCopy}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <CopyIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.copy)}
              </ListItemText>
            </MenuItem>)}
          </Menu>
        )
      }
      {
        showPositiveFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
          >
            <PositiveFeedback
              handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
      {
        showNegativeFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
          >
            <NegativeFeedback
              handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
      {
        (toolTipData && toolTipData.title) && (
          <div className={classes.tooltipWrapperStyle} style={{top: toolTipData.top, left: toolTipData.left}}>
            <div className={toolTipData.pageNo ? classes.toolipTitleStyle : `${classes.toolipTitleStyle} ${classes.fullWidth}`}>
              {toolTipData.title}
            </div>
              {
                toolTipData.pageNo && (
                  <Typography variant="body2" className={classes.pageStyle}>
                    {formatMessage(messages.page)} {toolTipData.pageNo}
                  </Typography>
                )
              }
              <ChainIcon />
          </div>
        )
      }
      { showReferenceLink && (<CustomReferenceLinkMessage 
          masterTheme={masterTheme}
          theme={theme} 
          customThemeStyles={customThemeStyles}
          pageId={pageReferenceData?.pageId}
          title={pageReferenceData?.title}
          onPageNavigation={onPageNavigation}
          sourceLinkUrl={sourceLinkUrl}
        />)
      }
    </Box>
  );
};

StreamingDiscuss.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleChatGPTCustomNote: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  botResponse: PropTypes.object,
  bookTitle: PropTypes.string,
  bookId: PropTypes.string,
  sourceLinkUrl: PropTypes.string,
  discussData: PropTypes.object,
  chapterId: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  onFormatChange: PropTypes.func,
  showFormat: PropTypes.bool,
  inputValue: PropTypes.string,
  explainFlowMessage: PropTypes.string,
  feedbackIconFocusId: PropTypes.object,
  responseOptionType: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  disableOptions: PropTypes.bool,
  videos: PropTypes.array,
  chatType: PropTypes.string,
  enableExplainQuizOptions: PropTypes.number.isRequired,
  onExplainQuiz: PropTypes.func.isRequired,
  onExplainMoveOn: PropTypes.func.isRequired,
  bookId: PropTypes.string.isRequired,
  explainQuizButton: PropTypes.bool,
  initSuggestToQuizEvent: PropTypes.func,
  showExplainQuiz: PropTypes.bool,
  channelId: PropTypes.string,
  env: PropTypes.string,
  videoRef: PropTypes.object,
  isExpanded: PropTypes.bool,
  initials: PropTypes.string,
  isOptInLinkEnabled: PropTypes.bool,
  showPremiumVideo: PropTypes.bool,
  showOptInPremiumMessage: PropTypes.bool,
  hideChannelsPopout: PropTypes.bool.isRequired,
  pageList: PropTypes.array,
  onPageNavigation: PropTypes.func,
  showSourceReferencePage: PropTypes.bool.isRequired,
  playList: PropTypes.array.isRequired,
  showOptoutLink: PropTypes.bool.isRequired,
  onHideChannelVideo: PropTypes.func.isRequired,
  onShowChannelVideo: PropTypes.func.isRequired,
  handleGAEvents: PropTypes.func,
  bookId: PropTypes.string,
  productId: PropTypes.string,
  isChannelsBundleSubscriber: PropTypes.bool,
  showPractice: PropTypes.bool.isRequired,
  onClickCallback: PropTypes.bool,
  customThemeStyles: PropTypes.object,
  customConfig: PropTypes.object,
  intentType: PropTypes.string
};

StreamingDiscuss.defaultProps = {
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  onFormatChange: () => {},
  showFormat: true,
  inputValue: '',
  channelId: '',
  env: '',
  sourceLinkUrl: '',
  videoRef: {},
  customThemeStyles: {},
  customConfig: {},
  feedbackIconFocusId: {},
  bookTitle: '',
  bookId: '',
  discussData: {},
  botResponse: {},
  explainFlowMessage: '',
  responseOptionType: constants.BULLET,
  disableOptions: false,
  initials: '',
  isOptInLinkEnabled: false,
  showPremiumVideo: false,
  showOptInPremiumMessage: false,
  videos: [],
  chatType: 'explain',
  explainQuizButton: true,
  initSuggestToQuizEvent: () => {},
  showExplainQuiz: true,
  isExpanded: false,
  pageList: [],
  onPageNavigation: () => {},
  bookId: '',
  productId: '',
  isChannelsBundleSubscriber: false,
  handleGAEvents: () => {},
  onClickCallback: () => {},
  intentType: ''
};
export default withStyles(styles)(injectIntl(StreamingDiscuss));
