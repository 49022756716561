import Divider from '@mui/material/Divider';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { Accordion, AccordionDetails, Typography, Chip } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: '10px 0'
  },
  heading: {
    fontSize: '15px !important',
    fontWeight: '600 !important'
  },
  subheading: {
    fontSize: '14px !important'
  },
  accordionDetails: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  activityType: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  statusMessage: {
    display: 'flex !important',
    flexDirection: 'column !important',
    width: '100% !important',
    alignItems: 'center !important',
    marginTop: '15px !important'
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
});

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'rotate(90deg)'
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1)
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)'
  })
}));

const PVSIngestionComponent = ({ data, classes }) => {
  const getActivityLabel = (type) => {
    let typeLabel = '';
    switch (type) {
      case 'ai_indexing':
        typeLabel = 'AI Indexing';
        break;
      case 'summary_generation':
        typeLabel = 'Summary Generation';
        break;
      case 'mcq_generation':
        typeLabel = 'MCQ Generation';
        break;
      case 'topics_generation':
        typeLabel = 'Topics Generation';
        break;
      case 'image_extraction':
        typeLabel = 'Image Extraction';
        break;
      default:
    }
    return typeLabel;
  };

  return (
    <div className={classes.root}>
      {data?.map((course, index) => (
        <Accordion key={index} style={{ minHeight: '60px' }}>
          <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div>
                <Typography className={classes.heading}>Course Id: {course?.courseId}</Typography>
                <Typography className={classes.subheading}>Manifest Id: {course?.manifestId}</Typography>
              </div>
              <div>
                <Typography className={classes.heading}>Status: {course?.status?.code}</Typography>
                <Typography className={classes.subheading}>Updated At: {course?.updatedAt}</Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography
              className={classes.heading}
              style={{ display: 'flex', justifyContent: 'center', textDecoration: 'underline', padding: '10px' }}
            >
              Ingestion Activities
            </Typography>
            {course?.activities?.map((activity, activityIndex) => (
              <>
                <div key={activityIndex}>
                  <Divider className={classes.heading}>
                    <Chip label={getActivityLabel(activity?.type)} size="medium" />
                  </Divider>
                  {activity?.statusMessages?.map((statusMessage, statusIndex) => (
                    <>
                      <hr width="1" size="20" style={{ margin: '0 auto' }} />
                      <div key={statusIndex} className={classes.statusMessage}>
                        <Typography className={classes.heading}>
                          Status: {statusMessage?.manifestProcessingStatusCode}
                        </Typography>
                        <Typography className={classes.subheading}>
                          Creation Date: {new Date(statusMessage?.creationDt).toLocaleString()}
                        </Typography>
                      </div>
                    </>
                  ))}
                  {activityIndex < course?.activities.length - 1 ? (
                    <>
                      <hr width="1" size="20" style={{ margin: '0 auto' }} />
                      <div style={{ margin: '-14px', textAlign: 'center' }}>
                        <KeyboardArrowDownIcon />
                      </div>
                      <hr width="1" size="20" style={{ margin: '0 auto' }} />
                    </>
                  ) : (
                    <Divider />
                  )}
                </div>
              </>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

PVSIngestionComponent.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PVSIngestionComponent);
