import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import * as constants from '../../../common/constants';
import env from '../../../common/env';

const DownloadFile = () => {
  const { fileId } = useParams();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!fileId) {
      setErrorMessage('Invalid file ID. Unable to download the file.');
      return;
    }

    const fetchSignedUrl = async () => {
      try {
        const param = { fileId };

        const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.REPORT_DOWNLOAD_S3_URL}`, param);

        const { signedUrl } = response.data;

        if (!signedUrl) {
          setErrorMessage('Failed to get the signed URL.');
          return;
        }

        const fileResponse = await axios.get(signedUrl, { responseType: 'blob' });

        const blob = new Blob([fileResponse.data], { type: 'text/csv' });
        const fileName = fileId.split('/').pop();

        // Create a link and trigger the download
        const aTag = document.createElement('a');
        aTag.href = URL.createObjectURL(blob);
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);

        // Redirect after download starts
        setTimeout(() => history.push('/chatgptutility'), 3000);
      } catch (error) {
        setErrorMessage('Error downloading file. Please try again.');
      }
    };

    fetchSignedUrl();
  }, [fileId, history]);

  return <div>{errorMessage ? <p>{errorMessage}</p> : 'Downloading your file...'}</div>;
};

export default DownloadFile;
